<template>
    <div class="global-app-wrapper">
        <div class="app-external-wrapper uk-animation-fade" dir="rtl" style="direction: rtl; z-index: 2;">
            <saffron class="app-external-wrapper uk-animation-fade" :use-meta="true" >
                <router-view v-slot="{ Component }" :key="$route.fullPath">
                    <component :is="Component"  uk-scrollspy="cls: uk-animation-fade;"/>
                </router-view>
            </saffron>
        </div>
    </div>
</template>

<script>
import SaffronComponent from '@/client/components/Saffron.vue';
import {useStore, mapGetters} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';

export default {
    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        return {asyncOps, asyncOpsReady, asyncStatus};
    },
    components: {
        Saffron: SaffronComponent
    },
    props : {
        'layout' : {
            type: String,
            default: 'default'
        },
        'forcedLayout' : {
            default: false,
            validator: (value) => (typeof value === 'string' || value === false)
       }
    },
    data() {
        return {
            secondsSinceLastActive: 0,
            letActivityCheckIntervalDuration : 10000,
            activityWatchInterval: null,
            activityEvents : [
                'mousedown', 'mousemove', 'keydown',
                'scroll', 'touchstart'
            ],
            navigation : {
                side: [],
                footer: [],
            },
            asyncData: {
                navigation: 'content/navigation',
            }
        }
    },
    computed: {
        ...mapGetters({
                          'hasUser'       :'talron/hasUser',
                      }),
    },
    methods: {
        async inactivityDisconnect() {
            // if there is an inactive window with an inactive user, this may prevent edge case of a quick disconnect
            this.secondsSinceLastActive = 0;

            if ( ! this.hasUser ) {
                return;
            }

            this.$s.ui.modal.alert(this.translate('talron.user.disconnectedModalContent'), this.translate('talron.user.disconnectedModalTitle')).then(() => {
               try {
                   window.location.reload();
               } catch(e) {

               }
            });
            await this.$store.dispatch('talron/logoutTalronUser');
            this.$router.push(config.user.guestLoginRedirect);
        },
        activityDetectedHandler() {
            this.secondsSinceLastActive = 0;
            this.$store.commit('talron/userStateCookieWithLastActivity')
        },
        tickInactivity() {
            this.secondsSinceLastActive = this.secondsSinceLastActive + (this.letActivityCheckIntervalDuration/1000);

            if (this.secondsSinceLastActive > config.talron.sessionLifeTime) {
                this.inactivityDisconnect();
            }
        },
        watchActivity() {
            this.activityWatchInterval = setInterval(this.tickInactivity, this.letActivityCheckIntervalDuration);
            //add these events to the document.
            //register the activity function as the listener parameter.
            this.activityEvents.forEach((eventName) => {
                document.addEventListener(eventName, this.activityDetectedHandler, true);
            });
        },
        stopWatchingActivity() {
            clearInterval(this.activityWatchInterval);
            this.activityEvents.forEach((eventName) => {
                document.removeEventListener(eventName, this.activityDetectedHandler, true);
            });
        },
    },
    watch : {
        navigation : {
            immediate: true,
            handler: function (newVal, oldVal) {
                this.$store.commit('talron/setAllNav', newVal);
            },
            deep: true
        }
    },
    mounted () {
      console.log('main mounted', config.talron);
        this.watchActivity();
    },
    beforeUnMount () {
        this.stopWatchingActivity();
    },
  }
</script>
<style lang="scss" scoped>
.global-app-wrapper {
    display: flex;
    flex-direction: column;
    flex: 100vh;
    min-height: 100vh;
}

</style>

<style lang="scss">
    h1:focus {
        outline: none;
    }
</style>
